import { onLoad } from './libs/helpers';

onLoad(hideMenuOnMobileOnScroll);

function hideMenuOnMobileOnScroll() {
  let wasScrolled = false;
  let lastScrollTop = 0;
  let delta = 5;
  const minimalScrollDistance = 100;

  window.addEventListener('scroll', () => (wasScrolled = true));

  setInterval(function () {
    if (wasScrolled) {
      onScroll();
      wasScrolled = false;
    }
  }, 250);

  function onScroll() {
    let scrollHeight = 0,
      scrollTop = 0,
      scrollThreshold = 0;
    if (document.body.scrollTop > 0) {
      scrollHeight = document.body.scrollHeight;
      scrollTop = document.body.scrollTop;
      scrollThreshold = 15;
    } else {
      scrollHeight = document.documentElement.scrollHeight;
      scrollTop = document.documentElement.scrollTop;
      scrollThreshold = 30;
    }

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - scrollTop) <= delta) {
      return;
    }

    const isMinimalScrollThresholdPassed = scrollTop > minimalScrollDistance;
    const isScrollDown = scrollTop > lastScrollTop;
    const percentageScrollLeft = ((scrollHeight - scrollTop) * 100) / scrollHeight;
    const isFarToBottom = percentageScrollLeft > scrollThreshold;
    const shouldHideMenu = isMinimalScrollThresholdPassed && isScrollDown && isFarToBottom;
    if (shouldHideMenu) {
      // Scroll Down
      document.body.classList.add('nav-hidden');
    } else {
      // Scroll Up
      document.body.classList.remove('nav-hidden');
    }

    lastScrollTop = scrollTop;
  }
}
